import React from 'react'

type FAQItem = {
  question: string
  answer: string
  linkText?: string
  linkHref?: string
}

const faqItems: FAQItem[] = [
  {
    question: 'Is there an AI that can create documents?',
    answer:
      'Yes, v0 Report is a cutting-edge AI document generator that efficiently creates high-quality documents. Using advanced natural language processing (NLP) algorithms, v0 Report produces well-structured, coherent, and contextually accurate documents.',
  },
  {
    question: 'What is an AI document?',
    answer:
      'An AI document refers to a document generated using artificial intelligence technologies. These documents are created by AI systems that understand and process human language, allowing them to write content that mimics human writing.',
  },
  {
    question: 'Can AI read a PDF document?',
    answer:
      'Yes, AI can read PDF documents. AI-powered tools like v0 Report are equipped with optical character recognition (OCR) capabilities, enabling them to extract and interpret text from PDF files.',
  },
  {
    question: 'Is there an AI that can read and summarize PDFs?',
    answer:
      'Absolutely! v0 Report includes advanced features that allow it to read and summarize PDF documents. By utilizing sophisticated algorithms, v0 Report can quickly analyze the content of a PDF, identify critical points, and generate concise summaries.',
  },
  {
    question: 'How secure is the data processed by AI document generators?',
    answer:
      'Security is a top priority for AI document generators like v0 Report. We implement robust encryption protocols to protect your data throughout the document creation process.',
  },
  {
    question: 'What types of documents can v0 Report generate?',
    answer:
      'v0 Report can generate a wide range of documents, including but not limited to business reports, research papers, summaries, and technical documentation. It is designed to adapt to various formats and styles to suit the specific needs of its users.',
  },
]

const FAQSection: React.FC = () => {
  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <h2 className="mb-8 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
          Frequently asked questions
        </h2>
        <div className="grid pt-8 text-left border-t border-gray-200 md:gap-16 dark:border-gray-700 md:grid-cols-2">
          {faqItems.map((item, index) => (
            <div key={index} className="mb-10">
              <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                {/* SVG icon */}
                <svg
                  className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                {item.question}
              </h3>
              <p className="text-gray-500 dark:text-gray-400">
                {item.answer}
                {item.linkText && (
                  <a
                    href={item.linkHref}
                    className="font-medium underline text-primary-600 dark:text-primary-500 hover:no-underline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.linkText}
                  </a>
                )}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default FAQSection
