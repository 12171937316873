import React, { useRef } from 'react'
import { useScroll, useTransform, motion } from 'framer-motion'
import { Separator } from './separator'

interface Company {
  name: string
  industry?: string // Optional
  headquarters?: string // Optional
  designation: string
  image: string
}

export const ContainerScroll = ({
  companies,
  titleComponent,
}: {
  companies: Company[]
  titleComponent: string | React.ReactNode
}) => {
  const containerRef = useRef<any>(null)
  const { scrollYProgress } = useScroll({
    target: containerRef,
  })
  const [isMobile, setIsMobile] = React.useState(false)

  React.useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768)
    }
    checkMobile()
    window.addEventListener('resize', checkMobile)
    return () => {
      window.removeEventListener('resize', checkMobile)
    }
  }, [])

  const scaleDimensions = () => {
    return isMobile ? [0.7, 0.9] : [1.05, 1]
  }

  const rotate = useTransform(scrollYProgress, [0, 1], [20, 0])
  const scale = useTransform(scrollYProgress, [0, 1], scaleDimensions())
  const translate = useTransform(scrollYProgress, [0, 1], [0, -100])

  return (
    <div
      className="md:h-[80rem] flex items-center justify-center relative p-4 md:p-20"
      ref={containerRef}
    >
      <div
        className="w-full relative"
        style={{
          perspective: '1000px',
        }}
      >
        <Header translate={translate} titleComponent={titleComponent} />
        <Card rotate={rotate} translate={translate} scale={scale} companies={companies} />
      </div>
    </div>
  )
}

export const Header = ({ translate, titleComponent }: any) => {
  return (
    <motion.div
      style={{
        translateY: translate,
      }}
      className="div max-w-5xl mx-auto text-center mb-12"
    >
      {titleComponent}
    </motion.div>
  )
}

export const Card = ({
  rotate,
  scale,
  translate,
  companies,
}: {
  rotate: any
  scale: any
  translate: any
  companies: Company[]
}) => {
  return (
    <motion.div
      style={{
        rotateX: rotate, // rotate in X-axis
        scale,
        boxShadow:
          '0 0 #0000004d, 0 9px 20px #0000004a, 0 37px 37px #00000042, 0 84px 50px #00000026, 0 149px 60px #0000000a, 0 233px 65px #00000003',
      }}
      className="max-w-5xl -mt-12 mx-auto h-[30rem] md:h-[40rem] w-full border-1 md:border-4 border-[#6C6C6C] p-3 md:p-6 bg-[#222222] rounded-[30px] shadow-2xl"
    >
      <div className="bg-gray-100 h-full w-full rounded-2xl grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4 overflow-hidden p-4">
        {companies.map((company, idx: number) => (
          <motion.div
            key={`user-${idx}`}
            className="border bg-white rounded-md relative"
            style={{ translateY: translate }}
            whileHover={{
              boxShadow: '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
            }}
          >
            <img
              src={company.image}
              className="rounded-tr-md rounded-tl-md text-sm w-full"
              alt="thumbnail"
            />
            <div className="p-4">
              <p className="font-semibold text-sm ">{company.name}</p>
              <p className=" text-gray-500 text-xs ">{company.industry}</p>
              <p className=" text-gray-500 text-xs ">{company.headquarters}</p>
              <Separator className="my-4" />
              <p className=" text-gray-500 text-xs ">{company.designation}</p>
            </div>
          </motion.div>
        ))}
      </div>
    </motion.div>
  )
}
