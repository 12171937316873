import { useQuery } from '@tanstack/react-query'

export const useSavedUrl = () => {
  const query = useQuery({
    queryFn: () => {
      return localStorage.getItem('url')
    },
    queryKey: ['saved-url'],
  })

  return query
}
