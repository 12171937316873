import { NonAppNavWrapper } from '@/components/generic/non-app-nav'
import FAQSection from '@/components/page-specific/index/faq'
import { ContainerScroll } from '@/components/ui/container-scroll-animation'
import { Input } from '@/components/ui/input'
import { useSavedUrl } from '@/hooks/use-saved-url'
import { useAuth, useUser } from '@clerk/nextjs'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  CircleDollarSign,
  CornerRightUp,
  MousePointerClick,
  Sparkles,
  ThumbsUp,
  Trophy,
} from 'lucide-react'
import { NextSeo } from 'next-seo'
import { Inter } from 'next/font/google'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

export const items = [
  {
    name: 'Starbucks as a Bank',
    designation: 'Consumer Behavior Analysis',
    image: '/images/screenshots/starbucks.png',
    industry: 'Food & Beverage',
    headquarters: 'Seattle, WA',
    website: 'https://www.starbucks.com',
    tag: 'Premier roaster and retailer of specialty coffee',
  },
  {
    name: 'How Technology is Changing Education',
    designation: 'Essay on Education Technology',
    image: '/images/screenshots/education-technology.png',
    subject: 'Education',
    overview: 'An exploration of digital tools transforming learning environments.',
  },
  {
    name: 'Microsoft Acquisition Strategy',
    designation: 'Technology Adoption Report',
    image: '/images/screenshots/microsoft.png',
    industry: 'Software',
    headquarters: 'Redmond, WA',
    website: 'https://www.microsoft.com',
    tag: 'World-leading software, services, and solutions',
  },
  {
    name: 'The Impact of Globalization',
    designation: 'Assignment on Global Economics',
    image: '/images/screenshots/globalization.png',
    subject: 'Economics',
    overview: 'Analyzing the effects of globalization on international markets.',
  },
  {
    name: 'Apple Inc.',
    designation: 'Innovation and Development Report',
    image: '/images/screenshots/apple.png',
    industry: 'Technology',
    headquarters: 'Cupertino, CA',
    website: 'https://www.apple.com',
    tag: 'Innovator in personal electronics',
  },
  {
    name: 'Climate Change and Sustainability',
    designation: 'Environmental Studies Essay',
    image: '/images/screenshots/climate-change.png',
    subject: 'Environmental Science',
    overview: 'Discussing strategies for combating climate change through sustainability.',
  },
  {
    name: 'IBM Legacy Technology',
    designation: 'Enterprise Technology Trends',
    image: '/images/screenshots/ibm.png',
    industry: 'Technology',
    headquarters: 'Armonk, NY',
    website: 'https://www.ibm.com',
    tag: 'Pioneering in computing and IT consulting',
  },
  {
    name: 'Nike Influencer Strategy',
    designation: 'Brand and Product Strategy Report',
    image: '/images/screenshots/nike.png',
    industry: 'Apparel',
    headquarters: 'Beaverton, OR',
    website: 'https://www.nike.com',
    tag: 'Leading global athletic footwear and apparel',
  },
  {
    name: 'Psychology of Social Media',
    designation: 'Psychology Assignment',
    image: '/images/screenshots/social-media-psychology.png',
    subject: 'Psychology',
    overview: 'Examining the psychological effects of social media usage.',
  },
  {
    name: 'Tesla Beta Testing',
    designation: 'Sustainability and Innovation Report',
    image: '/images/screenshots/tesla.png',
    industry: 'Automotive',
    headquarters: 'Palo Alto, CA',
    website: 'https://www.tesla.com',
    tag: 'Innovative electric vehicles and energy solutions',
  },
  {
    name: 'Netflix Price Hike',
    designation: 'Streaming Industry Analysis',
    image: '/images/screenshots/netflix.png',
    industry: 'Entertainment',
    headquarters: 'Los Gatos, CA',
    website: 'https://www.netflix.com',
    tag: 'Leading streaming service for movies and TV shows',
  },
  {
    name: 'Amazon AI Efforts',
    designation: 'E-commerce Market Analysis',
    image: '/images/screenshots/amazon.png',
    industry: 'Retail',
    headquarters: 'Seattle, WA',
    website: 'https://www.amazon.com',
    tag: 'World’s largest online retailer',
  },
  {
    name: 'Google Search Engine Technology',
    designation: 'Search Engine Technology Report',
    image: '/images/screenshots/google.png',
    industry: 'Technology',
    headquarters: 'Mountain View, CA',
    website: 'https://www.google.com',
    tag: 'Leading search engine and technology company',
  },
  {
    name: 'Facebook Stock Analysis',
    designation: 'Social Media Platform Analysis',
    image: '/images/screenshots/facebook.png',
    industry: 'Technology',
    headquarters: 'Menlo Park, CA',
    website: 'https://www.facebook.com',
    tag: 'Popular social media platform',
  },
  {
    name: 'Typeform Case Study',
    designation: 'Typeform Case Study',
    image: '/images/screenshots/typeform.png',
    industry: 'Technology',
    headquarters: 'San Francisco, CA',
    website: 'https://www.typeform.com',
    tag: 'Social media platform for news and updates',
  },
]

const inter = Inter({ subsets: ['latin'] })

const urlSchema = z.object({
  url: z.string(),
})

type UrlForm = z.infer<typeof urlSchema>

export default function Home() {
  const router = useRouter()
  const { isSignedIn } = useAuth()

  console.log(router.query)

  const { data: url } = useSavedUrl()

  const form = useForm<UrlForm>({
    resolver: zodResolver(urlSchema),
    defaultValues: {
      url: url || '',
    },
  })

  const { register, setValue, setFocus } = form

  React.useEffect(() => {
    setFocus('url')
  }, [setFocus])

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    router.push(isSignedIn ? '/pricing' : '/auth/signin')
  }

  const { user } = useUser()
  const isSubscribed = user?.publicMetadata.isSubscribed

  return (
    <>
      <NextSeo
        title="v0 report - Unleash the Power of Automated Report Generation"
        description="Discover v0 Report, your advanced AI Document Generator. In minutes, you can effortlessly create, customize, and manage professional-quality documents."
      />
      <NonAppNavWrapper>
        <main className={`scroll-smooth flex min-h-screen  flex-col ${inter.className}`}>
          <div className="mx-auto text-center mt-36 grid gap-24">
            <div>
              <p className="text-base text-zinc-500 mb-4">
                Now supports <span className="text-sky-600">Google Docs</span> 🥳
              </p>
              <h2 className="text-6xl text-zinc-700 grid gap-2">
                <span className="font-medium">v0 Report - Your Advanced AI</span>
                <span className="font-thin italic font-serif leading-none text-zinc-700">
                  Document Generator
                </span>
              </h2>
              <p className="w-3/5 mx-auto text-xl text-zinc-500 mt-4">
                Discover v0 Report, your advanced AI Document Generator. In minutes, you can
                effortlessly create, customize, and manage professional-quality documents.
              </p>
            </div>

            <form
              className="w-5/6 md:w-3/6 shadow-lg shadow-sky-300/70 hover:shadow-sky-300/90 focus-within:shadow-sky-300/90 text-white mx-auto flex items-center space-x-2 px-2 py-1 mb-7 bg-zinc-900 rounded-full"
              onSubmit={handleSubmit}
            >
              <Sparkles className="text-fray-500 ml-2 w-6 h-6" />
              <Input
                type="text"
                placeholder="What topic do you need a document on?"
                className="bg-zinc-900 flex-1 rounded-xl text-md border-transparent focus:border-zinc-900 focus:outline-none autofill-bg focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-offset-0"
              />
              <Link
                href={isSignedIn ? '/pricing' : '/auth/signin'}
                className="flex items-center space-x-2 bg-zinc-900 hover:bg-zinc-900 text-zinc-400 hover:text-white rounded-full"
                aria-label={'Get a report'}
              >
                <CornerRightUp className="w-4 h-4 mr-2" />
              </Link>
            </form>
          </div>

          <div className="pb-24 text-sm">
            <div className="flex flex-col overflow-hidden">
              <ContainerScroll companies={items} titleComponent={''} />
            </div>

            <div className="w-5/7 px-2 mt-12 md:mt-0 rounded-lg">
              <h1 className="mx-auto text-center text-4xl font-semibold mb-10">
                Transform how you approach your papers <br />
                and save valuable time with v0 Report.
              </h1>
              <p className="text-lg text-center text-zinc-500">
                Discover the power of v0 Report, your AI-powered generator for <br />
                all your research needs. This advanced document creator tool assists you in writing,
                editing, <br />
                and citing comprehensive document types with precision and ease.
              </p>
            </div>
          </div>

          <div className="px-1">
            <div className="rounded-3xl bg-zinc-100 p-16 mx-auto max-w-screen-xl flex items-center gap-0 md:gap-8 p-1">
              <div>
                <h2 className="w-fit px-2 mx-auto text-left text-3xl font-bold mb-10">
                  Transform Your Documents with v0 <br />
                  Report – The Leading AI Document Generator
                </h2>
                <p className="text-lg text-zinc-500">
                  At v0.report, we understand the need for precision, efficiency, and quality in
                  generating documents. <br />
                  <br />
                  Whether you're a student, researcher, or professional, our AI generator document
                  analytics are designed to automate your writing process, enhance workflow
                  productivity, and elevate the quality of your documents.
                </p>
              </div>

              <img
                src="/images/graphic/logo.jpg"
                className="mx-auto w-7/12 rounded-3xl text-sm hidden sm:block"
                alt="thumbnail"
              />
            </div>
          </div>

          <div className="bg-zinc-900 w-full my-24 py-24 text-white mx-auto text-center flex flex-col items-center">
            <h2 className="w-fit font-light mb-2 text-sky-300">
              Advanced AI Writing Research Capabilities
            </h2>
            <h3 className="text-3xl font-white mb-10 uppercase">
              AI Generator Designed for Your Research and Writing Skills
            </h3>
            <img
              src="/images/graphic/featured.svg"
              className="mx-auto w-16 text-sm"
              alt="thumbnail"
            />
          </div>

          <div className="mx-auto rounded-xl max-w-screen-lg p-1">
            <h2 className="w-fit px-2 mx-auto text-center text-3xl font-bold mb-10">
              Why Choose v0 Report?
            </h2>
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2 mx-auto mb-24">
              <div className="bg-zinc-100 p-8 rounded-3xl">
                <div className="flex items-center gap-2 w-fit">
                  <MousePointerClick className="w-9 h-9 text-sky-300 p-2 border border-zinc-300 rounded mr-2" />
                  <h3 className="text-lg font-medium ">Efficiency at Your Fingertips</h3>
                </div>
                <p className="mt-4 text-zinc-500">
                  Use the power of artificial intelligence to produce documents in minutes. Our
                  ai-generated document generator tool understands your needs, adapts to your style,
                  and delivers automated documents that meet your document specifications.
                </p>
              </div>
              <div className="bg-zinc-100 p-8 rounded-3xl">
                <div className="flex items-center gap-2 w-fit">
                  <Trophy className="w-9 h-9 text-sky-300 p-2 border border-zinc-300 rounded mr-2" />
                  <h3 className="text-lg font-medium ">Accuracy You Can Trust</h3>
                </div>
                <p className="mt-4 text-zinc-500">
                  With advanced language models and industry-specific knowledge, v0 Report ensures
                  that every document is not only generated quickly but also meets the highest
                  standards of quality and precision.
                </p>
              </div>
              <div className="bg-zinc-100 p-8 rounded-3xl">
                <div className="flex items-center gap-2 w-fit">
                  <ThumbsUp className="w-9 h-9 text-sky-300 p-2 border border-zinc-300 rounded mr-2" />
                  <h3 className="text-lg font-medium ">User-Friendly Workflow Interface</h3>
                </div>
                <p className="mt-4 text-zinc-500">
                  Our user-friendly AI document creator allows you to generate documents easily. No
                  technical expertise is required; just your input, and our AI document generator
                  takes care of the rest.
                </p>
              </div>
              <div className="bg-zinc-100 p-8 rounded-3xl">
                <div className="flex items-center gap-2 w-fit">
                  <CircleDollarSign className="w-9 h-9 text-sky-300 p-2 border border-zinc-300 rounded mr-2" />
                  <h3 className="text-lg font-medium ">Cost-Effective Writing Generator</h3>
                </div>
                <p className="mt-4 text-zinc-500">
                  Reduce operational costs with our AI-powered solutions. V0 Report's efficiency
                  eliminates the need for extensive manual document creation, saving time and money.
                </p>
              </div>
            </div>
          </div>
          <div className="mx-auto mt-36">
            <h2 className="w-fit px-2 mx-auto text-center text-3xl font-bold mb-20">
              V0 Report: Supported Content Types and AI <br />
              Assistance
            </h2>
            <div className="grid grid-cols-1 gap-16 md:grid-cols-2 w-11/12 md:w-7/12 mx-auto mb-24">
              <div className="my-auto">
                <h3 className="text-3xl font-semibold mb-2">Essays</h3>
                <p className="text-zinc-500">
                  Our AI essay writing tool is designed to save you considerable time while
                  maintaining high-quality standards. Its prompt helps essay structure arguments and
                  enhances overall essay content coherence, making essay writing not only faster but
                  also more effective.
                </p>
              </div>

              <img
                src="/images/graphic/essays.jpg"
                className="ml-auto w-80 text-sm hidden sm:block"
                alt="thumbnail"
              />
            </div>
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2 w-11/12 md:w-7/12 mx-auto mb-24">
              <img
                src="/images/graphic/literature.jpg"
                className="mr-auto w-80 text-sm hidden sm:block"
                alt="thumbnail"
              />
              <div className="my-auto">
                <h3 className="text-3xl font-semibold mb-2">Literature Reviews</h3>
                <p className="text-zinc-500">
                  Our AI literature assistance simplifies the process of finding, writing, and
                  referencing literature research and reviews. It helps you quickly identify key
                  studies and seamlessly add them to your literature review, ensuring that all
                  relevant information is covered efficiently.
                </p>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-16 md:grid-cols-2 w-11/12 md:w-7/12 mx-auto mb-24">
              <div className="my-auto">
                <h3 className="text-3xl font-semibold mb-2">Research Papers</h3>
                <p className="text-zinc-500">
                  Research papers require a high level of detail and accuracy. Our document
                  generative AI research assistant tool can help refine your research paper drafts,
                  suggest improvements, and ensure your arguments are well-supported by real data.
                </p>
              </div>

              <img
                src="/images/graphic/research.jpg"
                className="ml-auto w-80 text-sm hidden sm:block"
                alt="thumbnail"
              />
            </div>
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2 w-11/12 md:w-7/12 mx-auto mb-24">
              <img
                src="/images/graphic/pdf.jpg"
                className="mr-auto w-80 text-sm hidden sm:block"
                alt="thumbnail"
              />
              <div className="my-auto">
                <h3 className="text-3xl font-semibold mb-2">AI PDF Summarizer</h3>
                <p className="text-zinc-500">
                  The AI PDF Summarizer in our v0 tool makes it easier to get through long PDF
                  documents. It quickly picks out and summarizes the main points from large texts.
                  It is ideal for students, researchers, and professionals who need to understand
                  detailed reports, research papers, and PDF articles without reading every word.
                </p>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-16 md:grid-cols-2 w-11/12 md:w-7/12 mx-auto mb-24">
              <div className="my-auto">
                <h3 className="text-3xl font-semibold mb-2">AI Book Generator</h3>
                <p className="text-zinc-500">
                  Our AI Book Summarizer simplifies the process, quickly turning your detailed
                  concepts into clear, engaging book summaries. Whether you're working on a summary
                  for a novel, a how-to guide, or a technical manual, the v0 report tool helps you
                  go from idea to manuscript effortlessly.
                </p>
              </div>

              <img
                src="/images/graphic/book.jpg"
                className="ml-auto w-80 text-sm hidden sm:block"
                alt="thumbnail"
              />
            </div>

            <div className="grid grid-cols-1 gap-8 md:grid-cols-2 w-11/12 md:w-7/12 mx-auto mb-24">
              <img
                src="/images/graphic/thesis.jpg"
                className="mr-auto w-80 text-sm hidden sm:block"
                alt="thumbnail"
              />
              <div className="my-auto">
                <h3 className="text-3xl font-semibold mb-2">Thesis Writing</h3>
                <p className="text-zinc-500">
                  Our v0 tool is built to help students and researchers create strong arguments and
                  keep their thesis ideas flowing smoothly, all while making sure their thesis work
                  is honest and accurate. By making the most time-consuming parts of research and
                  writing faster, v0 tool not only saves you time but also improves the quality of
                  your thesis writing.
                </p>
              </div>
            </div>
          </div>

          <FAQSection />

          <div className="w-11/12 mx-auto my-48 md:w-fit text-center grid gap-8">
            <Link
              rel="canonical"
              href={isSubscribed ? '/home' : '/'}
              className="flex justify-center w-fit mx-auto"
            >
              <Image
                className="object-scale-down h-8 w-8"
                src="/images/logo.png"
                alt="Logo"
                width={200}
                height={300}
              />
              <p className="my-auto ml-4 hidden sm:block bg-gray-100 text-gray-600 px-3 py-1.5 rounded ">
                v0 report
              </p>
            </Link>
            <h2 className="text-3xl tracking-tight font-semibold leading-tight text-gray-900">
              Experience the future of document generation today <br />
              with v0 Report!
            </h2>
            <Link
              className="w-fit mx-auto h-8 rounded-md px-4 inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-sky-600 text-primary-foreground hover:bg-sky-600/90 "
              href={'/auth/signup'}
            >
              Get started
            </Link>
          </div>
        </main>
      </NonAppNavWrapper>
    </>
  )
}
